*,
*:before,
*:after {
	box-sizing: border-box;
}

html,
body {
	margin: 0;
	padding: 0;
}

html {
	color: rgb(241, 242, 244);
	color: black;
	background-color: rgb(248, 249, 243);
	scroll-behavior: smooth;
}

@font-face {
	font-family: Kokila;
	src: url('./fonts/kokila.ttf') format('opentype');
}

@font-face {
	font-family: Kokila Bold;
	font-weight: bold;
	src: url('./fonts/kokilab.ttf') format('opentype');
}

@font-face {
	font-family: Calibri Light;
	font-weight: 400;
	src: url('./fonts/Calibri\ Light.ttf') format('opentype');
}

body {
	font-family: 'Times New Roman', -apple-system, BlinkMacSystemFont,
		'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 1.2em;
}
